import Form from './form';
import Swiper from 'swiper';
const $ = require('jquery');
const tools = require('./tools');
import sukoaDIOG from './main.js';
const sukoaUtils = require('./vendor/sukoaUtils').sukoaUtils;

const diogBlog = {
    init: function () {

        $('.lazy').Lazy();

        sukoaDIOG.gallery();
        diogBlog.scrollTop();
        diogBlog.commentInteraction();
        diogBlog.dateTimeDisplay();
        diogBlog.blogPostDetails();
        diogBlog.initMobileBlogList();
        diogBlog.tagsList();
        diogBlog.blogArchivMultilineEllipsisText();
        diogBlog.initDateSlider();
        sukoaDIOG.slideshow();

        if(sukoaUtils.getBaseSetting('isAuthorInstance')) {
            $(".comment-to-app-link").click(function (e) {
                e.preventDefault();
                let url = $(this).attr("href");
                window.open(url);
            });
        }
    },


    blogArchivMultilineEllipsisText: function () {
        let stop = false;
        let textContainerHeight = $('.blog_archiv_multiline-ellipsis-text__parent_content').height() - 8;
        $('.blog_archiv_multiline-ellipsis-text__parent_content').find('.multilineEllipseText').each(function () {
            let multilineEllipseTextContainer = $(this);
            let multilineEllipseTextContainerHeight = $(this).outerHeight(true);
            let lastComponent = true;

            $($(this).find('.blog__component').get().reverse()).each(function () {
                let $blogComponent = $(this);

                if ((multilineEllipseTextContainerHeight - $(this).outerHeight(true)) > textContainerHeight) {
                    $(this).hide();

                } else {
                    if (lastComponent) {
                        $($(this).find('p').get().reverse()).each(function () {

                            if ((multilineEllipseTextContainerHeight - $(this).outerHeight()) > textContainerHeight) {

                                $(this).hide();
                                multilineEllipseTextContainerHeight = multilineEllipseTextContainerHeight - $(this).outerHeight(true);

                            } else {
                                let counter = 0;

                                while ($(multilineEllipseTextContainer).outerHeight(true) > textContainerHeight) {
                                    $(this).text(function (index, text) {
                                        return text.replace(/\W*\s(\S)*$/, '...');
                                    });
                                    if (counter > 200) {
                                        break;
                                    } else {
                                        counter++;
                                    }
                                }
                                stop = true;
                            }
                        });
                        if (stop) {
                            return false;
                        }
                        lastComponent = false;
                    }
                }

                if (stop) {
                    return false;
                }

                multilineEllipseTextContainerHeight = multilineEllipseTextContainerHeight - $(this).outerHeight(true);

            });

        });

    },


    scrollTop: function () {
        $('#goTop').show();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#goTop').stop().animate({
                    height: '40px'
                }, 500);
            } else if ($(this).scrollTop() < 50) {
                $('#goTop').stop().animate({
                    height: '0px'
                }, 500);
            }
        });
        $('#goTop').click(function () {
            $('html, body').stop().animate({
                scrollTop: 0
            }, 500, function () {
                $('#goTop').stop().animate({
                    height: '0px'
                }, 500);
            });
        });
    },

    commentInteraction: function () {
        let displayForm = function () {
            let commentWrapperOuter = $("#comment-wrapper-outer");
            if (commentWrapperOuter.length > 0) {
                let href = sukoaUtils.getCurrentURL(true).replace(".html", "~mgnlArea=comments~");
                href += "?display_comment_form=true&ck=" + new Date().getTime();
                $.get(href, function (data) {
                    let newForm = $("<div>" + data + "</div>").find("#comment-wrapper-outer .form-wrapper");
                    let currentWrapper = $("#comment-wrapper-outer");
                    let currentFormWrapper = currentWrapper.find(".form-wrapper");
                    currentFormWrapper.html(newForm.html());
                    let $commentForm = currentFormWrapper.find('form');
                    new Form($commentForm, 'commentForm');

                    sukoaDIOG.formStylingInit();
                });
            }
        };

        displayForm();

        //highlightin the comment
        let commentID = sukoaUtils.getParameterByName("highlightcomment");
        if (commentID != null && commentID.length > 0) {
            let target = $("#" + commentID);
            if (target.length > 0) {
                target.addClass("highlight-new");
                $('html,body').animate({scrollTop: target.offset().top - 60}, 500);
            }
        }

        let commentWrapperOuter = $("#comment-wrapper-outer");
        let commentWrapper = $("#comments-wrapper");
        commentWrapper.on("click", ".comment_reply", function (e) {
            e.preventDefault();
            let b = $(this);
            let commentId = $(this).parent().attr("id");
            b.parent().append(commentWrapperOuter.find(".form-wrapper"));
            $("#replyto").val(commentId);
        });
        commentWrapper.on("change", ".comment-state-select", function () {
            let commentstate = $(this).val();
            let commentDiv = $(this).closest(".comment-entry");
            let commentId = commentDiv.attr("id");
            commentDiv.removeClass("comment-submitted comment-enabled comment-disabled").addClass("comment-" + commentstate);
            $.get(window.location.pathname + "?changeCommentState=" + commentstate + "&commentId=" + commentId);
        });
        commentWrapper.on("click", ".delete-comment", function (e) {
            let commentDiv = $(this).closest(".comment-entry");
            let childrenDivs = "";
            let commentId = commentDiv.attr("id");
            let childrenCount = commentDiv.attr("data-children-count");
            let dialogText = "Diesen Kommentar ";
            if (childrenCount !== "0") {
                dialogText += "\ninklusive " + childrenCount + " Antworten\n";
                childrenDivs = commentDiv.nextUntil(".level-1");
                childrenDivs.addClass("to-delete");
            }
            commentDiv.addClass("to-delete");
            dialogText += "defintiv löschen?";
            window.setTimeout(function () {
                if (confirm(dialogText)) {
                    $.get(window.location.pathname + "?deleteCommentId=" + commentId, function (data) {
                        commentDiv.fadeOut(300);
                        if (childrenCount != "0") {
                            childrenDivs.fadeOut(100);
                        }
                    });
                } else {
                    commentDiv.removeClass("to-delete");
                    if (childrenCount != "0") {
                        childrenDivs.removeClass("to-delete");
                    }
                }
            }, 50);
        });
    },
    dateTimeDisplay: function () {
        if (jQuery().timeago) {
            jQuery.timeago.settings = {
                refreshMillis: 60000,
                allowPast: true,
                allowFuture: true,
                cutoff: 0,
                autoDispose: true,
                strings: {
                    prefixAgo: "vor",
                    prefixFromNow: "in",
                    suffixAgo: "",
                    suffixFromNow: "",
                    seconds: "wenigen Sekunden",
                    minute: "etwa einer Minute",
                    minutes: "%d Minuten",
                    hour: "etwa einer Stunde",
                    hours: "%d Stunden",
                    day: "etwa einem Tag",
                    days: "%d Tagen",
                    month: "etwa einem Monat",
                    months: "%d Monaten",
                    year: "etwa einem Jahr",
                    years: "%d Jahren"
                }
            };

            $("time.timeago").timeago();
        }
    },
    tagsList: function () {
        $('.tags-list__groups').on('click', '.tag--group', function (e) {
            let parent = $(this).parent();
            let clickedTag = $(parent).find(".tags__sub-tags");

            if ($(parent).hasClass("open")) {
                $(parent).removeClass("open");
                clickedTag.slideUp();
            } else {
                let activeTag = $('.tags-list__groups').find(".open");
                activeTag.find(".tags__sub-tags").slideUp();
                activeTag.removeClass("open");

                clickedTag.slideDown();
                parent.addClass("open")
            }
        });

        $('.tag-reset').click(function () {
            let cleanUrl = $(this).attr('cleanUrl');
            window.location = cleanUrl;
        });
    },
    initDateSlider: function () {

        let initialSlideContent = $(".swiper-container-blog-date .current-year");
        let initialSlideIndex = $(".swiper-container-blog-date .ul-sub li").index(initialSlideContent);

        let mySwiper = new Swiper('.swiper-container-blog-date', {
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
            initialSlide: initialSlideIndex
        });

    },
    showBlogDetail: function ($detail, data, heightConstant) {

        $detail.hide();

        // get the data content
        let detailData = $($(data).find('.blog-post-not-excerpt-list')).html();
        let detailCommentData = $($(data).find('.comments__wrapper')).html();

        // get the detail row
        let detailRow = $detail.find('.detail-row');

        // append new data content to the previos detail
        detailRow.html(detailData + detailCommentData);

        $detail.slideDown(800);
        $('.filter-list-overlay-loading').fadeOut();
    },


    initMobileBlogList: function () {
        function initTabHeight() {
            let brandingHeight = 0;
            if ($('#branding').outerHeight(true) != null) {
                brandingHeight = $('#branding').outerHeight(true);
            }
            let blogFilterHeight = 0;
            if ($('#blog-filter').outerHeight(true) != null) {
                blogFilterHeight = $('#blog-filter').outerHeight(true);
            }
            let mobileSidebarHeight = 0;
            if ($('#tabs-blog-sidebar_mobile').outerHeight(true) != null) {
                mobileSidebarHeight = $('#tabs-blog-sidebar_mobile').outerHeight(true);
            }

            let headerHeight = brandingHeight + blogFilterHeight + mobileSidebarHeight;
            let footerHeight = 32;


            let contentHeight = 300;

            let windowsHeight = $(window).height();
            let windowsWidth = $(window).width();
            contentHeight = windowsHeight - headerHeight - footerHeight;

            if ($('#tabs-blog-sidebar_mobile .tabs-content') != null) {

                $('#tabs-blog-sidebar_mobile .tabs-content .ui-tabs-panel').each(function () {
                    $(this).css('max-height', '' + contentHeight + 'px').css({height: '' + contentHeight + 'px'});
                });
            }

            if (windowsWidth <= 640) {
                $('.blog-list-column').css({
                    height: '' + contentHeight + 'px',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                });
            } else {
                $('.blog-list-column').css({height: ''});
            }
        }

        initTabHeight();

        $(window).resize(function () {
            initTabHeight();
        });

    },

    addBlogRelatedTab: function (data) {
        // get the data content
        let relatedTabTitleData = $($(data).find('.tabs-title.related')).html();
        let relatedTabContentData = $($(data).find('.tabs-content .content#related')).html();


        let blogPostSideBar = $(".blog-post__sidebar");
        $(blogPostSideBar).each(function () {
            let relatedTabTitle = $(this).find(".tabs-title.related");
            let relatedTabContent = $(this).find(".tabs-content .content#related");
            let mobileRelatedTabContent = $(this).find(".tabs-content .content#mobile-related");


            // append new data content to the previos detail
            relatedTabContent.html(relatedTabContentData);
            mobileRelatedTabContent.html(relatedTabContentData);

            if (!$(this).hasClass("mobile_sidebar")) {
                relatedTabTitle.html(relatedTabTitleData);
                relatedTabTitle.find("a").click();
            } else {
                relatedTabTitle.removeClass("hide");
            }
        })

    },

    lastPostClicked: null,
        lastPostLinkClicked: null,

        blogPostDetails: function () {

        $('body').on('click', '.blog-post-more:not(.edit-mode)', function (e) {
            e.preventDefault();

            if (!$(this).hasClass("no_hide")) {
                $(this).hide();
            } else {
                $(this).parent().find('.blog-post-more:not(.edit-mode, .no_hide)').hide();
            }
            $('.filter-list-overlay-loading').fadeIn();

            let cleanUrl = $(this).find('a.blog-post-morelink').attr('href');
            let separator = (cleanUrl.indexOf("?") > -1 ? "&" : "?");
            let isEditMode = "";
            if (typeof sukoaUtils.getBaseSetting("isEditMode") !== "undefined") {
                isEditMode = "&isEditMode=" + sukoaUtils.getBaseSetting("isEditMode");
            }
            let queryUrl = cleanUrl + separator + "ajax=true" + isEditMode;

            let detail = $(this).parent().find(".detail-row-wrapper");

            // if detail already is shown
            if (diogBlog.lastPostClicked != null) {
                let currentTopScrollPosition = $(document).scrollTop();
                let lastPostClickedHeight = diogBlog.lastPostClicked.height();
                let lastPostClickedTopPosition = diogBlog.lastPostClicked.position().top;

                diogBlog.lastPostClicked.fadeOut(0);
                diogBlog.lastPostClicked.find(".detail-row").empty();

                let linkToShow = diogBlog.lastPostLinkClicked;
                $(linkToShow).show(0);

                if (e.pageY > lastPostClickedTopPosition) {
                    $('html, body').animate({
                        scrollTop: (currentTopScrollPosition - lastPostClickedHeight + $(linkToShow).outerHeight(true))
                    }, 0);
                }


            }

            $.ajax({
                url: queryUrl,
                method: "GET",
                success: function (data) {

                    diogBlog.showBlogDetail(detail, data, "previousHeight");
                    diogBlog.addBlogRelatedTab(data);

                    let newTitle = $(data).filter('title').text();
                    sukoaDIOG.historyPushState(cleanUrl, newTitle);

                    sukoaDIOG.fadeInLoadedEntries();
                    diogBlog.commentInteraction();
                    sukoaDIOG.slideshow();
                }
            });

            diogBlog.lastPostClicked = detail;
            diogBlog.lastPostLinkClicked = this;
        });
    }
};

export default diogBlog;
