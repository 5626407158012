import {sukoaUtils} from "./vendor/sukoaUtils";

class NewsletterArchive {
    constructor($elt) {
        this.$elt = $elt;
        this.init();
    }

    init() {
        this.$iframe = this.$elt.querySelector('iframe');
        this.$detailLinks = this.$elt.querySelectorAll("div[data-list-detaillink]")

        this.$detailLinks.forEach(($elt) => {
            $elt.addEventListener('click', (e) => {
                e.preventDefault();
                this.$iframe.src = $elt.dataset.listDetaillink;
                history.pushState({}, '', $elt.querySelector("a").href);

                this.$detailLinks.forEach(($elt) => {
                    $elt.classList.remove('active');
                })
                $elt.classList.add('active');

            })
        })
    }
}

function init() {
    if (!sukoaUtils.getBaseSetting("isEditMode")) {
        document.querySelectorAll('.newsletterArchive').forEach(($elt) => {
            new NewsletterArchive($elt)
        })
    }
}

export default init
