const $ = require('jquery');
const tools = require('./tools');
import sukoaDIOG from './main.js';
import diogBlog from './blog.js';
import ViewListDL from './datalayer/views/ViewListDL'

const sukoaUtils = require('./vendor/sukoaUtils').sukoaUtils;

const diogFilter = {
    nextPageParams: null, // parameters
    actionUrl: null, // baseUrl for calling the component directly
    cleanUrl: null, // baseUrl for history.js plugin
    filterMessage: "",
    gettingOtherFormat: false,

    init: function () {
        let $filter = $('.filter-wrapper');
        let $filterList = $('.filter-list');
        let $filterForm = $filter.find('.filter-form');
        diogFilter.actionUrl = $filterForm.attr('action');



        //Bookfilter page: the full functionality
        if ($filterList.length > 0) {

            diogFilter.autoCompleteBind();
            diogFilter.submit();
            diogFilter.historyInit();
            diogFilter.resetFilter();
            diogFilter.getDetail();
            diogFilter.getOtherFormat();
            diogFilter.bindInterface();

            diogFilter.cleanUrl = $filterList.data('clean-url');
            diogFilter.nextPageParams = $filterList.data('next-page-params');
            diogFilter.filterMessage = $filterList.data('filter-message');

            //bind page load
            let avidLoadingList = $filterList.data('avoid-loading-list');
            if (!avidLoadingList && avidLoadingList !== "true") {
                diogFilter.scrollLoad();
                $(window).on('scroll', function () {
                    diogFilter.scrollLoad();
                });
            }


            //Title page conditional: only bind interactive form functions
        } else {
            diogFilter.historyInit();
            diogFilter.bindInterface();
            diogFilter.submitRedirect();
            diogFilter.autoCompleteBind();
        }
    },

    bindInterface: function () {

        let filter = $('.filter-wrapper');

        //bind dropdown functionality
        filter.on('mouseover', '.filter-item:not(.filter-item-autocomplete)', function () {
            let t = $(this);
            let dropdown = t.find("ul");
            if (!t.hasClass('active'))
                dropdown.show();
        }).on('mouseout', '.filter-item:not(.filter-item-autocomplete)', function () {
            let t = $(this);
            let dropdown = t.find("ul");
            if (!dropdown.hasClass('active'))
                dropdown.hide();
        });

        //bind different dropdown functionality for autocomplete
        filter.on('mouseenter', '.filter-item-autocomplete', function () {
            let t = $(this);
            let dropdown = t.find("ul");
            if (!t.hasClass('active'))
                dropdown.show();
            t.find('.ui-autocomplete-input').focus();
        }).on('mouseleave', '.filter-item', function () {
            let t = $(this);
            let dropdown = t.find("ul");
            if (!dropdown.hasClass('active'))
                dropdown.hide();
            t.find('.ui-autocomplete-input').focusout();
        });

        //bind search input animation on hover
        filter.on('focus', '.ui-autocomplete-input', function () {
            $(this).closest('ul').animate({
                width: '20.533333333rem'
            }, "fast");
        });

        filter.on('focusout', '.ui-autocomplete-input', function () {
            $(this).closest('ul').css({width: '9.2666666667rem'});
        });

        filter.on("autocompleteclose", '.ui-autocomplete-input', function (event, ui) {
            let t = $(this).parent().find('input[type="text"]');

            //Resets the filter form
            let filterValue = t.siblings('input').val();
            if (filterValue === undefined || filterValue === '') {
                t.val("");
                t.closest(".filter-item.active").find(".filter-reset").click()
            }
        });

        //add active class to autocomplete filters
        filter.on('keyup change paste', '.filter-item-autocomplete .ui-autocomplete-input', function () {
            let t = $(this);
            let ul = $(this).closest('ul');
            if (t.val() !== "") {
                ul.addClass('active');
            } else {
                ul.removeClass('active');
            }
        });

        // basic dropdown select fn
        filter.on('click', '.filter-dropdown label', function () {
            let t = $(this);
            if (!t.hasClass('disabled')) {
                let current = t.text();
                let filter = t.closest('.filter-item');
                let selected = filter.find(".selected");
                selected.text(current);
                selected.parent().addClass('active');
                selected.show();
            }
        });

        // basic dropdown date
        filter.on('click', '.filter-dropdown-date .filter-title', function () {
            diogBlog.initDateSlider();
        }).on('mouseenter', '.filter-dropdown-date:not(.open)', function () {
            diogBlog.initDateSlider();
            $(".filter-dropdown-date").addClass("open");
        }).on('mouseleave', '.filter-dropdown-date.open', function () {
            $(".filter-dropdown-date").removeClass("open");
        });

        filter.on('click', '.filter-dropdown-date label', function () {
            let t = $(this);
            if (!t.hasClass('disabled')) {
                let current = t.text();
                let filter = t.closest('.filter-item');
                let selected = filter.find(".selected");
                selected.text(current);
                selected.parent().addClass('active');
                selected.show();
            }
        });

        // special case for filter group
        filter.on('click', '.filter-dropdown-variant > ul > li', function () {
            let t = $(this);
            let current = t.find("label").text();
            let filter = t.parent().parent();
            let filterTitle = filter.find(".filter-title");
            filterTitle.text(current);
        });

        // special case for sort input
        filter.on('click', '.sort-item li > label', function (e) {
            e.preventDefault();
            let t = $(this);
            let allLabels = $(".sort-item  li > label");
            let ascendingElement = $('#ascending');

            let requestSortOrder = sukoaUtils.getParam(window.location.href, "sortorder");
            let clickedSortOrder = t.find('input').val();
            allLabels.removeClass('active');

            if (clickedSortOrder === requestSortOrder) {
                ascendingElement.prop('checked', !ascendingElement.is(':checked'));
            }

            t.addClass('active').find('input').prop('checked', true).trigger('change');
        });

        let icons = filter.find('.icon');
        if (icons.length === 1) {
            if (icons.hasClass('author-icon')) {
                let a = icons.find('a');
                if (a.length > 0) {
                    let href = a.attr('href');
                    if (href) {
                        window.location.href = href;
                    }
                }
            } else {
                icons.trigger("click");
            }
        }
    },

    submitRedirect: function () {
        let form = $('.filter-form');
        let formRedirectUrl = form.data('redirect');
        if (formRedirectUrl) {
            form.on('change submit', function (e) {
                e.preventDefault();
                let serialized = $(this).find('input[value!=""]').serialize();
                let cleanUrl = "?" + serialized;
                window.location.href = formRedirectUrl + cleanUrl;
            });
        }
    },

    submit: function () {
        let filter = $('.filter-wrapper');
        filter.on('change', '.filter-item:not(".filter-item-autocomplete")', function (e) {
            let form = $('.filter-form');
            form.submit();
        });

        filter.on('change', '.sort-item', function (e) {
            let form = $('.filter-form');
            form.submit();
        });

        filter.on('submit', '.filter-form', function (e) {
            e.preventDefault();

            let filterList = $('.filter-list');
            let filterForm = $('.filter-form-wrapper');
            let fitlerPagination = $('.filter__pagination');
            let loading = $('.filter-list-overlay-loading');

            // action speaks to specific component
            let serialized = $(this).find('input[value]:not([value=""])').serialize();
            let query;
            if (serialized) {
                query = "?" + serialized;
            } else {
                query = "";
            }
            let cleanUrl = diogFilter.cleanUrl + query;
            let queryUrl = diogFilter.actionUrl + query;

            sukoaDIOG.gettingNewPage = false;

            if (sukoaDIOG.ajaxRequest && sukoaDIOG.ajaxRequest.readyState !== 4) {
                sukoaDIOG.ajaxRequest.abort();
            }
            loading.fadeIn();
            sukoaDIOG.ajaxRequest = $.ajax({
                url: queryUrl + "&ajax=true",
                method: "GET",
                dataType: "html",
                success: function (data) {
                    let dom = $('<div>' + data + '</div>');
                    diogFilter.nextPageParams = dom.find('.filter-list').data('next-page-params');
                    diogFilter.filterMessage = dom.find('.filter-list').data('filter-message');


                    let fitlerListContent = $(dom.find('.filter-list').html());
                    let fitlerFormContent = $(dom.find('.filter-form-wrapper').html());
                    let fitlerPaginationContent = $(dom.find('.filter__pagination').html());

                    filterList.html(fitlerListContent);
                    filterForm.html(fitlerFormContent);
                    fitlerPagination.html(fitlerPaginationContent);


                    let icons = fitlerListContent.find('.icon');

                    if (!(diogFilter.nextPageParams) && icons.length === 1) {
                        if (icons.hasClass('author-icon')) {

                            let a = icons.find('a');

                            if (a.length > 0) {
                                let href = a.attr('href');
                                if (href) {
                                    window.location.href = href;
                                }
                            }
                        } else {
                            icons.trigger("click");
                        }
                    }


                    // loading.fadeOut("fast");
                    sukoaDIOG.initWidgetConsent();

                    sukoaDIOG.fadeInLoadedEntries();
                    //todo hiere
                    let newTitle = $(data).filter('title').text();
                    sukoaDIOG.historyPushState(cleanUrl, newTitle);
                    diogFilter.autoCompleteBind();
                    diogFilter.scrollLoad();

                    if (filterList.hasClass("blog")) {
                        diogBlog.blogArchivMultilineEllipsisText();
                        diogBlog.dateTimeDisplay();
                    }
                },
                complete:  function () {
                    loading.fadeOut("fast");
                }
            });
        });
    },

    resetFilter: function () {
        let filter = $('.filter-wrapper');
        filter.on('click', '.filter-item.active .filter-title', function () {
            let form = $('.filter-form');
            let filter = $(this).parent();
            if (filter.hasClass('filter-item-autocomplete')) {
                filter.find('input').val("");
                filter.removeClass("active");
                filter.find("ul").removeClass('active');
            } else {
                filter.find('input:checked').prop('checked', false);
                filter.removeClass("active");
            }
            form.submit();
        });
    },

    scrollLoad: function () {
        let filterList = $('.filter-list');
        let infiniteScroll = filterList.attr('data-filter-infinite-scroll');
        if (!infiniteScroll) {
            infiniteScroll = "true";
        }

        let windowheight = $(window).height();
        let documentheight = $(document).height();
        let windowScrollTop = $(window).scrollTop();
        let loading = $('.filter-list-overlay-loading');
        let offset = 100;

        if (infiniteScroll === "true") {
            if (windowheight >= (documentheight - offset) || (windowheight + windowScrollTop >= (documentheight - offset))) {

                if (sukoaDIOG.gettingNewPage === false) {

                    if (diogFilter.nextPageParams !== undefined && diogFilter.nextPageParams !== "") {
                        sukoaDIOG.gettingNewPage = true;
                        loading.stop().fadeIn(200);
                        let nextUrl = diogFilter.actionUrl + "?" + diogFilter.nextPageParams;
                        if (!diogFilter.actionUrl) {
                            nextUrl = diogFilter.cleanUrl + "?" + diogFilter.nextPageParams;
                        }

                        $.ajax({
                            url: nextUrl + "&ajax=true&onlyList=true",
                            method: "GET",
                            dataType: "html",
                            success: function (data) {
                                let dom = $('<div>' + data + "</div>");
                                let nextPageBooks = $(dom.find('.filter-list-wrapper').html());

                                $('.filter-list-wrapper').append(nextPageBooks);
                                loading.stop().fadeOut(200);

                                //sukoaDIOG.historyPushState(cleanUrl);
                                let filterList = dom.find('.filter-list');
                                diogFilter.nextPageParams = filterList.data('next-page-params');
                                diogFilter.filterMessage = filterList.data('filter-message');
                                sukoaDIOG.fadeInLoadedEntries(nextPageBooks);

                                sukoaDIOG.gettingNewPage = false;

                                sukoaDIOG.initWidgetConsent()

                                if (filterList.hasClass("blog")) {
                                    diogBlog.blogArchivMultilineEllipsisText();
                                    diogBlog.dateTimeDisplay();
                                }

                                //DATALAYER//
                                tools.executeDataLayerBy("views", new ViewListDL(filterList[0]));
                                //DATALAYER//
                            }
                        });
                    } else {
                        $('.filter-list-message').html(diogFilter.filterMessage);
                    }
                }
            }
        }

        if (Cookiebot.runScripts !== undefined) {
            Cookiebot.runScripts()
        }

    },

    historyInit: function () {
        let History = window.History;
        if (!History.enabled) {
            return false;
        }
        // uses a flag to check if browser back is clicked.
        History.Adapter.bind(window, 'statechange', function () {
            let state = History.getState();
            if (sukoaDIOG.manualStateChange) {
                window.location.replace(state.url);
            }
            sukoaDIOG.manualStateChange = true;
        });
    },

    autoCompleteBind: function () {
        // Author Lookup Field
        $('#author-lookup-field').autocomplete({
            source: getAuthorSuggestions,
            delay: 1,
            minLength: 1,
            selectFirst: true,
            autoFocus: true,

            response: function (event, ui) {

                if (!ui.content.length) {
                    let titleSearchEmptyResult = $('.filter-form').attr("data-author-search-empty-result") || "keine Ergebnisse gefunden";
                    let noResult = {value: "", label: titleSearchEmptyResult};
                    ui.content.push(noResult);
                }
            },
            select: function (event, ui) {
                let authorInput = $('input[name=filter_author_id]');
                if (ui.item.authorid) {
                    authorInput.val(ui.item.authorid);
                    authorInput.parent().parent().parent().addClass('active');

                    // reset group filters to "alle" so that a specific author can always be found
                    let filters = $('.filter-group, .filter-genre, .filter-format, .filter-country, .filter-role');
                    if (filters.length > 0) {
                        filters.find('input').prop('checked', false);
                        filters.find('input#id-Alle').prop('checked', true);
                    }
                    $('.filter-form ').submit();
                }
                return true;
            },
            focus(event, ui) {
                let $menulist = $(event.currentTarget);
                $menulist.find('.ui-state-focus').removeClass('ui-state-focus');
                $menulist.find('.ui-state-active').parent().addClass('ui-state-focus');
            },
            position: {
                of: $('#filter-author > ul')
            }
        });

        // Product Lookup Field
        $('#product-lookup-field').autocomplete({
            source: getProductSuggestions,
            delay: 1,
            minLength: 1,
            selectFirst: true,
            autoFocus: true,
            response: function (event, ui) {

                if (!ui.content.length) {
                    let titleSearchEmptyResult = $('.filter-form').attr("data-title-search-empty-result") || "keine Ergebnisse gefunden";
                    let noResult = {value: "", label: titleSearchEmptyResult};
                    ui.content.push(noResult);
                }
            },
            select: function (event, ui) {
                let productInput = $('input[name=filter_product_id]');
                if (ui.item.productid) {

                    productInput.val(ui.item.productid);
                    productInput.parent().parent().parent().addClass('active');
                    // reset group filter to "alle" so that a specific title can always be found
                    let filters = $('.filter-group, .filter-genre, .filter-format');
                    if (filters.length > 0) {
                        filters.find('input').prop('checked', false);
                        filters.find('input#id-Alle').prop('checked', true);
                    }
                    $('.filter-form').submit();
                }

                return true;
            },

            focus(event, ui) {
                let $menulist = $(event.currentTarget);
                $menulist.find('.ui-state-focus').removeClass('ui-state-focus');
                $menulist.find('.ui-state-active').parent().addClass('ui-state-focus');
            },
            position: {
                of: $('#filter-az > ul')
            }
        });
    },

    getDetail: function () {
        $('body').on('click', '.icon.title-icon.ajax', function (e) {
            e.preventDefault();
            if ($(e.target).hasClass('format')) {
                return false;
            }

            let cleanUrl = $(this).find('[data-detaillink]').find('.detaillink').attr('href');
            let regularUrl = sukoaUtils.getURLWithoutParameters(sukoaDIOG.regularUrl);
            let separator = (cleanUrl.indexOf("?") > -1 ? "&" : "?");
            let isEditMode = "";
            if (typeof sukoaUtils.getBaseSetting("isEditMode") !== "undefined") {
                isEditMode = "&isEditMode=" + sukoaUtils.getBaseSetting("isEditMode");
            }
            let queryUrl = cleanUrl + separator + "ajax=true" + isEditMode;
            let t = $(this);
            let filterList = $('.filter-list');

            if (sukoaDIOG.lastBookClicked === this && sukoaDIOG.gettingNewDetail === false) {

                sukoaDIOG.detail.slideToggle(function () {
                    filterList.removeClass('detail-is-active');
                    if ($(this).is(':hidden')) {
                        sukoaDIOG.historyPushState(regularUrl);
                        tools.scrollTo(t, 500, 80);
                    } else {
                        sukoaDIOG.historyPushState(cleanUrl, sukoaDIOG.lastBookClickedTitle);
                    }
                });
            } else {

                let detail = $('<div class="detail-row-wrapper loading large-30 column"><div class="detail-row row"></div></div>');
                let insertObject = diogFilter.getDetailInsertPoint(t);
                let previousDetail = $('.detail-row-wrapper');
                let height = 470;
                if (sukoaDIOG.previousHeight != null) {
                    height = sukoaDIOG.previousHeight;
                }

                // if detail already is shown
                if (previousDetail.length > 0) {
                    detail.hide().height(height).insertBefore(insertObject);
                    if (previousDetail.is(':hidden')) {
                        previousDetail.remove();
                        detail.slideDown(200);
                    } else {
                        previousDetail.remove();
                        detail.fadeIn(200);
                    }
                    // tools.scrollTo(detail, 100);
                    tools.scrollTo(detail, 100, 80);
                    // if detail doesn't exist
                } else {
                    detail.hide().height(height).insertBefore(insertObject);
                    detail.slideDown(200);
                    // tools.scrollTo(detail, 100);
                    tools.scrollTo(detail, 100, 80);
                }

                if (sukoaDIOG.gettingNewDetail === false) {
                    sukoaDIOG.gettingNewDetail = true;
                    $.ajax({
                        url: queryUrl,
                        method: "GET",
                        success: function (data) {

                            filterList.addClass('detail-is-active');

                            sukoaDIOG.displayTitleDetailsInline(detail, data, "previousHeight");
                            sukoaDIOG.detail = detail;

                            sukoaDIOG.titleDetailPresseSwiperInit();

                            let newTitle = $(data).filter('title').text();
                            sukoaDIOG.historyPushState(cleanUrl, newTitle);
                            sukoaDIOG.gettingNewDetail = false;

                            sukoaDIOG.lastBookClickedTitle = newTitle;

                        }
                    });
                }
            }

            sukoaDIOG.lastBookClicked = this;

        });
    },

    getOtherFormat: function () {

        // $('body').on('click', '.format', function (e) {
        $('.filter-list').on('click', '.format', function (e) {
            e.preventDefault();

            let cleanUrl = $(this).attr('href');
            let separator = (cleanUrl.indexOf("?") > -1 ? "&" : "?");
            let queryUrl = cleanUrl + separator + "ajax=true&isEditMode=" + sukoaUtils.getBaseSetting("isEditMode");
            let t = $(this);
            let previousDetail = $('.detail-row-wrapper');
            previousDetail.addClass('loading');

            if (diogFilter.gettingOtherFormat === false) {
                diogFilter.gettingOtherFormat = true;
                $.ajax({
                    url: queryUrl,
                    method: "GET",
                    success: function (data) {
                        sukoaDIOG.displayTitleDetailsInline(previousDetail, data, "previousHeight");
                        sukoaDIOG.titleDetailPresseSwiperInit();
                        diogFilter.gettingOtherFormat = false;
                        let newTitle = $(data).filter('title').text();
                        sukoaDIOG.historyPushState(cleanUrl, newTitle);
                    }
                });
            }

        });
    },

    getDetailInsertPoint: function (element) {
        let filter = $(".filter-list");
        let infiniteScroll = filter.attr('data-filter-infinite-scroll');
        if (!infiniteScroll) {
            infiniteScroll = "true";
        }
        let indexElement;
        if (infiniteScroll === "false") {
            let itemsPerRow = Math.floor($(".filter-list-wrapper", filter).innerWidth() / (Math.round(element[0].getBoundingClientRect().width * 10) / 10));
            // let itemsPerRow = Math.floor(window.innerWidth / element[0].clientWidth);
            let group = element.parent();
            let groupIcons = group.find('.icon');
            let index = groupIcons.index(element);
            let insertPointIndex = Math.floor(index / itemsPerRow) * itemsPerRow;
            indexElement = groupIcons.get(insertPointIndex);
        } else {

            let itemsPerRow = Math.floor($(".filter-list-wrapper", filter).innerWidth() / element[0].getBoundingClientRect().width);
            let $icon = $('.filter-list .icon');
            let index = $icon.index(element);
            let insertPointIndex = Math.floor(index / itemsPerRow) * itemsPerRow;
            indexElement = $icon.get(insertPointIndex);
        }
        return indexElement;
    }
};


/* returns a list of fuzzy matched authors for the given select request.
 Fuzzy matching is done with Levenshtein Distance.
 The author names are splitted to handle double first or last names.
 the splitted author names are substringed by the length of the current searchterm.
 Result is sorted by levendings (lowest is best) and name.
 */
let getAuthorSuggestions = function (request, response) {

    let MAX_LEVENSHTEIN_DISTANCE = 2;  // Number of edits required
    let term = request.term.trim().toLowerCase();
    let r = [];
    for (let i1 = 0; i1 < searchInfoAuthor.length; ++i1) {
        let si = searchInfoAuthor[i1];
        let lastname = si['lastname'];
        let firstname = si['firstname'];

        // Splitting names into an array to handle double names
        let strings = lastname.split(" ");
        strings = strings.concat(firstname.split(" "));
        strings.push(firstname);
        strings.push(lastname);
        strings.push(firstname + " " + lastname);
        removeFromArray(strings, "und");

        let score = 9999999;
        for (let i2 = 0; i2 < strings.length; ++i2) {
            if (strings[i2].toLowerCase().indexOf(term) >= 0) {
                score = 0;
                break;
            } else {
                let s = strings[i2].substring(0, term.length).toLowerCase();
                let levDist = getLevenshteinDistance(s, term);
                score = Math.min(score, levDist);
            }
        }

        // add if score ok
        if (score <= Math.min(term.length - 1, MAX_LEVENSHTEIN_DISTANCE)) {
            let label = lastname;
            if (!isEmptyString(firstname) && !isEmptyString(lastname)) {
                label += ", ";
            }
            label += firstname;
            let d = {
                label: label,
                authorid: si.recordid,
                score: score
            };
            r.push(d);
        }
    }

    // Sort result by score, lastname
    r.sort(dynamicSortMultiple("score", "label"));

    response(r);
};

let getProductSuggestions = function (request, response) {

    let MAX_LEVENSHTEIN_DISTANCE = 2;  // Number of edits required
    let term = request.term.trim().toLowerCase();
    let r = [];

    for (let i1 = 0; i1 < searchInfoProduct.length; ++i1) {
        let debug = false;
        let si = searchInfoProduct[i1];

        let titleProperties = ["titleFull", "titleForeignRights", "titleOriginal"];
        let title = si[titleProperties[0]];

        let $body = $('body');
        if ($body.hasClass('rights') || $body.hasClass('filmrights')) {
            if(si[titleProperties[1]]) {
                title = si[titleProperties[1]];
            }
        }

        let titles = [];
        for (let i4 = 0; i4 < titleProperties.length; i4++) {
            let property = titleProperties[i4];
            if (property in si) {
                let value = si[property];
                if (value && value !== "") {
                    titles.push(value);
                }
            }
        }

        // Splitting titles into an array of single words and all possible sentence fragments
        if (!("splitted" in si)) {
            if (debug) {
                let start = new Date().getTime();
            }
            si['splitted'] = [];
            for (let i5 = 0; i5 < titles.length; i5++) {
                let tmpTitle = titles[i5];
                if (tmpTitle !== "") {
                    let splittedTitle;
                    let tmpSplittedArray = splittedTitle = tmpTitle.split(" ");
                    let numberOfParts = splittedTitle.length;
                    for (let startPosition = 0; startPosition < Math.max(0, numberOfParts - 2); startPosition++) {
                        for (let fragmentLength = 2; fragmentLength < numberOfParts - startPosition; fragmentLength++) {
                            let endPos = startPosition + fragmentLength;
                            let fragment = splittedTitle.slice(startPosition, endPos).join(" ");
                            tmpSplittedArray.push(fragment);
                        }
                    }
                    si['splitted'].push(tmpSplittedArray);
                }
            }
            if (debug) {
                let end = new Date().getTime();
                let time = end - start;
            }
        }

        let strings = si['splitted'];
        let score = 9999999;
        for (let i2 = 0; i2 < strings.length; i2++) {
            let titleStrings = strings[i2];
            for (let i6 = 0; i6 < titleStrings.length; i6++) {
                let s = titleStrings[i6].substring(0, term.length).toLowerCase();
                let levDist = getLevenshteinDistance(s, term);
                score = Math.min(score, levDist);
            }
        }

        for (let i7 = 0; i7 < titles.length; i7++) {
            let tmpTitle = titles[i7];
            if (tmpTitle.toLowerCase().indexOf(term) > -1) {
                score = 1;
                if (tmpTitle.toLowerCase().indexOf(term) === 0) {
                    score = 0;
                }
                break;
            }
        }

        if (score <= Math.min(term.length - 1, MAX_LEVENSHTEIN_DISTANCE) || title.toLowerCase().indexOf(term) > -1) {

            let d = {
                label: title,
                productid: si.ID_Product,
                score: score
            };

            r.push(d);

        }

        //r.sort(dynamicSortMultiple("score", "label"));    // todo: auch alphabetisch sortieren
        r.sort(dynamicSortMultiple("score"));
    }
    response(r);
};

let getLevenshteinDistance = function (s, t) {
    let d = [];

    let n = s.length;
    let m = t.length;

    if (n === 0) {
        return m;
    }
    if (m === 0) {
        return n;
    }

    for (let i = n; i >= 0; i--) d[i] = [];

    for (let i = n; i >= 0; i--) d[i][0] = i;
    for (let j = m; j >= 0; j--) d[0][j] = j;

    for (let i = 1; i <= n; i++) {
        let s_i = s.charAt(i - 1);

        for (let j = 1; j <= m; j++) {

            if (i == j && d[i][j] > 4) {
                return n;
            }
            let t_j = t.charAt(j - 1);
            let cost = (s_i == t_j) ? 0 : 1; // Step 5

            let mi = d[i - 1][j] + 1;
            let b = d[i][j - 1] + 1;
            let c = d[i - 1][j - 1] + cost;

            if (b < mi) {
                mi = b;
            }
            if (c < mi) {
                mi = c;
            }

            d[i][j] = mi;

            if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
                d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
            }
        }
    }
    return d[n][m];
};

let dynamicSortMultiple = function () {
    /* Arguments object is an array-like object consisting of the names of the properties to sort by */
    let props = arguments;
    return function (obj1, obj2) {
        let i = 0, result = 0, numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
};

let dynamicSort = function (property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

let removeFromArray = function (arr, item) {
    let i;
    while ((i = arr.indexOf(item)) !== -1) {
        arr.splice(i, 1);
    }
};


let isEmptyString = function (str) {
    return !(str && 0 !== str.length);
};

export default diogFilter;
