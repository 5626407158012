import DefaultHandler from "./DefaultHandler";
import ViewsHandler from "./views/ViewsHandler";

export default {
    handlers: {},
    initialized: false,

    init() {
        if (window.dataLayer) {
            const defaultHandler = new DefaultHandler();

            const viewsHandler = new ViewsHandler();
            viewsHandler.init();

            this.handlers = {
                'default': defaultHandler,
                'views': viewsHandler
            }
            this.initialized = true;
        }
    },

    update() {
        if (window.dataLayer) {
            if(this.handlers) {
                for(const name in this.handlers) {
                    this.handlers[name].update()
                }
            }
        } else {
            this.deactivate();
        }
    },

    deactivate() {
        if (this.handlers) {
            for(const name in this.handlers) {
                this.handlers[name].deactivate()
            }
        }
    },

    executeBy(handlerName, dataLayerEvent, event) {
        if (this.initialized) {
            if(this.handlers[handlerName]) {
                this.handlers[handlerName].execute(dataLayerEvent, event);
            } else {
                this.handlers['default'].execute(dataLayerEvent, event);
            }
        } else {
            console.log(`DataLayerHandler not initialized Handler:[${handlerName}] Event:[${JSON.stringify(dataLayerEvent)}]`);
        }

    }
};
